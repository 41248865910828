import React, { useState, useEffect, useRef } from "react";
import NavigationBar from "../components/NavigationBar";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import AboutElements from "../components/About";

const About = () => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
    const aboutRef = useRef(null);
    const weRef = useRef(null);
    const eduRef = useRef(null);
    const routeHistory = useHistory();
    const executeAboutScroll = () => aboutRef.current.scrollIntoView({ behavior: "smooth" });
    const executeWEScroll = () => weRef.current.scrollIntoView({ behavior: "smooth" });
    const executeEduScroll = () => eduRef.current.scrollIntoView({ behavior: "smooth" });

    useEffect(() => {
        if (window.location.pathname === "/about" && window.location.hash === "") {
            executeAboutScroll();
        } else if (window.location.pathname === "/about" && window.location.hash === "#we") {
            executeWEScroll();
        } else if (window.location.pathname === "/about" && window.location.hash === "#edu") {
            executeEduScroll();
        }

        console.log(routeHistory);

        return routeHistory.listen((location) => {
            if (location.pathname === "/about" && location.hash === "") {
                executeAboutScroll();
            } else if (location.pathname === "/about" && location.hash === "#we") {
                executeWEScroll();
            } else if (location.pathname === "/about" && location.hash === "#edu") {
                executeEduScroll();
            }
        });
    }, [routeHistory]);

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return (
        <>
            <Helmet>
                <title>About | Tanaseth Julerttrakul</title>
            </Helmet>

            <Sidebar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <NavigationBar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <div style={{ bottom: 0, width: 0, height: 0, position: "absolute" }} ref={aboutRef}></div>
            <AboutElements aboutRef={aboutRef} weRef={weRef} eduRef={eduRef} />
        </>
    );
};

export default About;

import React from "react";
import {
    HomeContainer,
    HomeComponentsContainer,
    HelloContainer,
    TitleTextContainer,
    TitleText,
    SubtitleTextContainer,
    SubtitleMenu,
    SubtitleMenuA,
} from "./HomeElements.js";

const HomeElememts = () => {
    return (
        <>
            <HomeContainer>
                <HomeComponentsContainer>
                    <HelloContainer>
                        <TitleTextContainer>
                            <TitleText>
                                Hello,
                                <br />
                                I'm an engineering student who also produces music.
                            </TitleText>
                        </TitleTextContainer>
                    </HelloContainer>

                    <SubtitleTextContainer>
                        <SubtitleMenu to="/about">About me ></SubtitleMenu>
                        <SubtitleMenu to="/projects">Projects ></SubtitleMenu>
                        <SubtitleMenuA href={process.env.REACT_APP_MUSIC_URL_SUBDOMAIN + window.location.host}>Music ></SubtitleMenuA>
                    </SubtitleTextContainer>
                </HomeComponentsContainer>
            </HomeContainer>
        </>
    );
};

export default HomeElememts;

import React from 'react';
import { FaBars } from 'react-icons/fa'
import {
    Navigation,
    NavigationContainer,
    NavigationLogo,
    NavigationMenu,
    NavigationIcon,
    NavigationLink,
    NavigationLinkA,
    NavigationItem
} from './NavigationBarElements.js';

function NavigationBar(props) {
    return (
        <>
            <Navigation>
                <NavigationContainer>
                    <NavigationLogo to="/">TANASETH<br />JULERTTRAKUL</NavigationLogo>
                    <NavigationIcon onClick={props.toggleSidebar}>
                        <FaBars />
                    </NavigationIcon>
                    <NavigationMenu>
                        <NavigationItem>
                            <NavigationLink to="/about">ABOUT</NavigationLink>
                        </NavigationItem>
                        <NavigationItem>
                            <NavigationLink to="/projects">PROJECTS</NavigationLink>
                        </NavigationItem>
                        <NavigationItem>
                            <NavigationLinkA href={process.env.REACT_APP_MUSIC_URL_SUBDOMAIN + window.location.host}>♫ MUSIC</NavigationLinkA>
                        </NavigationItem>
                        <NavigationItem>
                            <NavigationLink to="/contact">CONTACT</NavigationLink>
                        </NavigationItem>
                        <NavigationItem>
                            <NavigationLink to="/resume">RESUME</NavigationLink>
                        </NavigationItem>
                    </NavigationMenu>
                </NavigationContainer>
            </Navigation>
        </>
    )
}

export default NavigationBar;
import React, { useState, useEffect, useRef } from "react";
import NavigationBar from "../components/NavigationBar";
import Sidebar from "../components/Sidebar";
import HomeElements from "../components/Home";
import ContactElements from "../components/Contact";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

const Home = () => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const contactRef = useRef(null);
    const homeRef = useRef(null);
    const routeHistory = useHistory();
    const executeContactScroll = () => contactRef.current.scrollIntoView({ behavior: "smooth" });
    const executeHomeScroll = () => homeRef.current.scrollIntoView({ behavior: "smooth" });

    useEffect(() => {
        if (window.location.pathname === "/contact") {
            setTitle("Contact");
            executeContactScroll();
        } else if (window.location.pathname === "/") {
            setTitle("Home");
            executeHomeScroll();
        }

        return routeHistory.listen((location) => {
            if (location.pathname === "/contact") {
                setTitle("Contact");
                executeContactScroll();
            } else if (location.pathname === "/") {
                setTitle("Home");
                executeHomeScroll();
            }
        });
    }, [routeHistory]);

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return (
        <>
            <Helmet>
                <title>{title} | Tanaseth Julerttrakul</title>
            </Helmet>

            <Sidebar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <NavigationBar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <div style={{ flexDirection: "column" }}>
                <HomeElements/>
                <div style={{ top: 0, bottom: 0, width: 0, height: 0 }} ref={homeRef}></div>
                <ContactElements/>
                <div style={{ bottom: 0, width: 0, height: 0, position: "absolute" }} ref={contactRef}></div>
            </div>
        </>
    );
};

export default Home;

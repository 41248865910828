import React from "react";
import { FaBars } from "react-icons/fa";
import {
    MusicNavigation,
    MusicNavigationContainer,
    MusicNavigationLogo,
    MusicNavigationMenu,
    MusicNavigationIcon,
    MusicNavigationLink,
    MusicNavigationItem,
    MusicNavigationLogoContainer,
    MusicNavigationLinkA,
} from "./MusicNavigationBarElements";

import { ReactComponent as ArtistLogo } from "../../../img/artist_logo.svg";

const home_url = process.env.REACT_APP_URL_PROTOCOL + window.location.host.split('.').slice(1).join('.');

function MusicNavigationBar(props) {
    return (
        <>
            <MusicNavigation>
                <MusicNavigationContainer>
                    <MusicNavigationLogoContainer>
                        <MusicNavigationLogo to="/music">
                            <ArtistLogo height="24px" width="auto" color="#fff" style={{ filter: "invert(1)", alignSelf: "center" }} />
                        </MusicNavigationLogo>
                    </MusicNavigationLogoContainer>

                    <MusicNavigationIcon onClick={props.toggleSidebar}>
                        <FaBars />
                    </MusicNavigationIcon>
                    <MusicNavigationMenu>
                        {/* <MusicNavigationItem>
                            <MusicNavigationLink to="/music/about">ABOUT</MusicNavigationLink>
                        </MusicNavigationItem>
                        <MusicNavigationItem>
                            <MusicNavigationLink to="/music/discography">♫ DISCOGRAPHY</MusicNavigationLink>
                        </MusicNavigationItem>
                        <MusicNavigationItem>
                            <MusicNavigationLink to="/music/contact">CONTACT</MusicNavigationLink>
                        </MusicNavigationItem> */}
                        <MusicNavigationItem>
                            <MusicNavigationLinkA href={home_url}>TANASETH.CO</MusicNavigationLinkA>
                        </MusicNavigationItem>
                    </MusicNavigationMenu>
                </MusicNavigationContainer>
            </MusicNavigation>
        </>
    );
}

export default MusicNavigationBar;

import styled, { keyframes } from "styled-components";
import { FaRegCopy, FaCheck } from "react-icons/fa";

// import background from "../../img/contact_bg.JPG";

const background = "https://res.cloudinary.com/ktns/image/upload/v1631027515/backgrounds/contact_bg_ohurar.jpg";

export const copiedToClipboardTextTransition = keyframes`
    0% {
        transform: translateY(-10px);
        opacity: 0%;
    }

    10% {
        transform: translateY(0px);
        opacity: 100%;
    }

    90% {
        transform: translateY(0px);
        opacity: 100%;
    }

    100% {
        transform: translateY(-10px);
        opacity: 0%;
    }
`;

export const ContactContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: "BasierSquare";

    background-color: #fff;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.23573179271708689) 10%, rgba(0, 0, 0, 0) 25%), url(${background});
    background-position: 0 55%;
    background-repeat: no-repeat;

    @media screen and (max-height: 800px) {
        background-position: 0 0%;
    }

    @media screen and (max-width: 600px) {
        background-position: 0% 0%;
    }

    @media screen and (max-height: 450px) {
        height: 200%;
        max-height: 200%;
        top: 200%;
    }
`;

export const SocialMediaWrapper = styled.div`
    text-align: center;
    bottom: 15%;
    position: absolute;

    mix-blend-mode: difference !important;
    filter: invert(1);

    @media screen and (max-height: 900px) {
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-evenly;

        @media screen and (max-width: 650px) {
            flex-direction: column;
            width: auto;
            justify-content: center;
        }
    }
`;

export const SocialMediaTextContainer = styled.div`
    font-size: 36px;
    font-weight: 600;

    @media screen and (max-width: 875px) {
        font-size: 26px;
    }
`;

export const SocialMediaLink = styled.a`
    color: #000;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #999;
        transition: 0.2s ease-in-out;
    }
`;

export const TitleTextContainer = styled.div`
    font-size: 72px;
    font-weight: 600;
    text-decoration: none;

    @media screen and (max-width: 875px) {
        font-size: 40px;
    }
`;

export const TitleText = styled.text`
    color: #000;
`;

export const ContactWrapper = styled.div`
    height: 30%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    mix-blend-mode: difference !important;
    filter: invert(1);
`;

export const ContactAndEmailContainer = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

export const EmailTextContainer = styled.div`
    font-size: 46px;
    font-weight: 600;
    text-decoration: none;
    outline: 3px solid #000;
    height: 64px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;

    @media screen and (max-width: 875px) {
        font-size: 20px;
        height: 40px;
        padding-left: 6px;
        padding-right: 6px;
    }
`;

export const EmailText = styled.text`
    color: #000;
    cursor: pointer;

    &:hover {
        color: #999;
        transition: 0.2s ease-in-out;
    }
`;

export const CopyIcon = styled(FaRegCopy)`
    color: #000;
    cursor: pointer;

    &:hover {
        color: #999;
        transition: 0.2s ease-in-out;
    }
`;

export const CopiedIcon = styled(FaCheck)`
    color: #000;
    cursor: pointer;

    &:hover {
        color: #999;
        transition: 0.2s ease-in-out;
    }
`;

export const CopyIconContainer = styled.div`
    height: 64px;
    width: 64px;
    outline: 3px solid #000;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 875px) {
        font-size: 20px;
        height: 40px;
        width: 40px;
    }
`;

export const EmailTextAncCopyIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    z-index: 10;
`;

export const CopiedToClipboardTextContainer = styled.div`
    opacity: 100%;
    font-size: 28px;
    font-weight: 600;
    margin-top: 10px;
    animation: ${copiedToClipboardTextTransition} 2s ease-in-out;
    width: fit-content;
    text-align: center;

    @media screen and (max-width: 875px) {
        font-size: 16px;
    }
`;

export const CopiedToClipboardText = styled.text`
    color: #000;
`;

import React from "react";
import {
    EduAndWEBackground,
    EducationComponentsContainer,
    EducationContainer,
    EducationDate,
    EducationObject,
    EducationObjectsContainer,
    EducationObjectSubtitle,
    EducationObjectTitle,
    EducationObjectTitleComponentsContainer,
    EducationTitle,
    EducationTitleContainer,
    SubtitleMenu,
    WorkExperienceComponentsContainer,
    WorkExperienceContainer,
    WorkExperienceDate,
    WorkExperienceObject,
    WorkExperienceObjectsContainer,
    WorkExperienceObjectSubtitle,
    WorkExperienceObjectTitleCompanyName,
    WorkExperienceObjectTitleComponentsContainer,
    WorkExperienceObjectTitlePosition,
    WorkExperienceTitle,
    WorkExperienceTitleContainer,
} from "./AboutElements";
import {
    AboutContainer,
    IntroductionSubtitleContainer,
    IntroductionTextContainer,
    IntroductionTitle,
    IntroductionTitleContainer,
    IntroductionSubtitle,
    AboutComponentContainer,
    SubtitleMenuContainer,
} from "./AboutElements";

const AboutElements = (props) => {
    return (
        <>
            <AboutContainer>
                <div style={{ top: 0, width: 0, height: 0, position: "absolute" }} ref={props.aboutRef}></div>
                <AboutComponentContainer>
                    <IntroductionTextContainer>
                        <IntroductionTitleContainer>
                            <IntroductionTitle>Hi again!</IntroductionTitle>
                        </IntroductionTitleContainer>
                        <IntroductionSubtitleContainer>
                            <IntroductionSubtitle>
                                <br />
                                I'm Tanaseth Julerttrakul – a student from Bangkok, Thailand, majored in information and communication engineering. As you might expect, I can code and do stuff with my
                                computer. Interested in software engineering, frontend development, and user experience design, I always try to find an opportunity to push my boundary forward.
                                <br />
                                <br />
                                Apart from engineering, I like to listen to, and also, make music. Producing videos, photography, and creative writing are my cup of tea, too.
                            </IntroductionSubtitle>
                        </IntroductionSubtitleContainer>
                    </IntroductionTextContainer>

                    <SubtitleMenuContainer>
                        <SubtitleMenu to={{ pathname: "about", hash: "edu" }}>Education ></SubtitleMenu>
                        <SubtitleMenu to={{ pathname: "about", hash: "we" }}>Work experience ></SubtitleMenu>
                        <SubtitleMenu to="resume">Resume ></SubtitleMenu>
                    </SubtitleMenuContainer>
                </AboutComponentContainer>
            </AboutContainer>

            <EduAndWEBackground>
                <EducationContainer>
                    <div style={{ top: 0, width: 0, height: 0, position: "absolute" }} ref={props.eduRef}></div>

                    <EducationComponentsContainer>
                        <EducationTitleContainer>
                            <EducationTitle>Education</EducationTitle>
                        </EducationTitleContainer>

                        <EducationObjectsContainer>
                            <EducationObject>
                                <EducationDate>2006–2018</EducationDate>
                                <EducationObjectTitleComponentsContainer>
                                    <EducationObjectTitle>Kasetsart University Laboratory School</EducationObjectTitle>
                                </EducationObjectTitleComponentsContainer>
                                <EducationObjectSubtitle>Majored in Science and Mathematics</EducationObjectSubtitle>
                            </EducationObject>

                            <EducationObject>
                                <EducationDate>2019–Present</EducationDate>
                                <EducationObjectTitleComponentsContainer>
                                    <EducationObjectTitle>Chulalongkorn University</EducationObjectTitle>
                                </EducationObjectTitleComponentsContainer>
                                <EducationObjectSubtitle>Majored in Information and Communication Engineering (International Programme)</EducationObjectSubtitle>
                            </EducationObject>
                        </EducationObjectsContainer>
                    </EducationComponentsContainer>
                </EducationContainer>

                <WorkExperienceContainer>
                    <div style={{ top: 0, width: 0, height: 0, position: "absolute" }} ref={props.weRef}></div>

                    <WorkExperienceComponentsContainer>
                        <WorkExperienceTitleContainer>
                            <WorkExperienceTitle>Work experience</WorkExperienceTitle>
                        </WorkExperienceTitleContainer>

                        <WorkExperienceObjectsContainer>
                            <WorkExperienceObject>
                                <WorkExperienceDate>July 2020</WorkExperienceDate>
                                <WorkExperienceObjectTitleComponentsContainer>
                                    <WorkExperienceObjectTitleCompanyName target="_blank" href="https://www.skilllane.com/" companyColour="rgb(122, 179, 119)">
                                        Skilllane
                                    </WorkExperienceObjectTitleCompanyName>
                                    <WorkExperienceObjectTitlePosition> · Video Producer</WorkExperienceObjectTitlePosition>
                                </WorkExperienceObjectTitleComponentsContainer>
                                <WorkExperienceObjectSubtitle>Produced lesson videos used in General Education programme for Chulalongkorn University</WorkExperienceObjectSubtitle>
                            </WorkExperienceObject>
                            <WorkExperienceObject>
                                <WorkExperienceDate>June–July 2021</WorkExperienceDate>
                                <WorkExperienceObjectTitleComponentsContainer>
                                    <WorkExperienceObjectTitleCompanyName target="_blank" href="https://www.linebk.com/" companyColour="rgb(78, 204, 95)">
                                        KASIKORN LINE
                                    </WorkExperienceObjectTitleCompanyName>
                                    <WorkExperienceObjectTitlePosition> · Software Engineer Intern</WorkExperienceObjectTitlePosition>
                                </WorkExperienceObjectTitleComponentsContainer>
                                <WorkExperienceObjectSubtitle>Worked on service backend and employees' system frontend using Golang and React.js</WorkExperienceObjectSubtitle>
                            </WorkExperienceObject>
                        </WorkExperienceObjectsContainer>
                    </WorkExperienceComponentsContainer>
                </WorkExperienceContainer>
            </EduAndWEBackground>
        </>
    );
};

export default AboutElements;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const transitionIn = keyframes`
    from {
        -webkit-transform: translateY(20px);
        opacity: 0%;
    }

    to {
        -webkit-transform: translateY(0px);
        opacity: 100%;
    }
`;

export const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const MusicVideoBackgroundContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -10;
    background-color: #fff;

    @media screen and (max-height: 450px) {
        height: 200%;
    }
`;

export const MusicVideoBackground = styled.video`
    position: absolute;
    width: 100%;
    height: auto;
    top: 30%;
    left: 50%;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;
    transform: translate(-50%, -30%);
    overflow: hidden;
`;

export const MusicContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    font-family: "BasierSquare";

    will-change: opacity;
    mix-blend-mode: difference;

    @media screen and (max-height: 450px) {
        height: 200%;
    }
`;

export const MusicComponentsContainer = styled.div`
    position: absolute;
    height: 100%;
    max-height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }
`;

export const MusicTitleContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
    position: absolute;
    top: 10%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 90%;
    max-width: 500px;

    @media screen and (max-width: 1800px) {
        top: 5%;
        max-width: 400px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        top: 0%;
        max-width: 400px;
    }

    @media screen and (max-width: 600px) {
        top: 0%;
        max-width: 350px;
    }
`;

export const MusicTitleTextContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    text-decoration: none;
    width: 90%;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const MusicTitleText = styled.text``;

export const ArtistTitleTextContainer = styled.div``;

export const ArtistTitleText = styled.text`
    font-size: 72px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 36px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 28px;
    }
`;

export const MusicSubtitleTextContainer = styled.div`
    font-size: 36px;
    width: 100%;
    font-weight: 600;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const MusicSubtitleText = styled.text``;

export const MusicHeroTitleContainer = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    max-height: 100%;
    justify-content: center;
    align-items: center;
`;

export const ArtistTitleContainer = styled.div`
    display: flex;
    width: 15%;
    height: 10%;
    max-height: 10%;
    justify-content: center;
    align-items: center;
`;

export const MusicMenuContainer = styled.div`
    font-size: 36px;
    font-weight: 600;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 85%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.9s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
`;

export const MusicMenu = styled.a`
    text-decoration: none;
    color: #fff;
    padding: 7px 18px 7px 18px;
    border-radius: 1000px;
    border: 4px solid transparent;
    text-align: center;
    background-color: transparent;

    &:hover {
        text-decoration: none;
        color: #000;
        padding: 7px 18px 7px 18px;
        border-radius: 1000px;
        border: 4px solid transparent;
        text-align: center;
        background-color: #fff;
    }
`;

export const MusicSocialMediaIconsContainer = styled.div`
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MusicSocialMediaBarContainer = styled.div`
    max-height: 45px;
    align-items: center;
    top: 92%;
    position: absolute;
    justify-content: space-evenly;
    flex-direction: row;
    display: flex;
`;

import React, { useState } from "react";
import NavigationBar from "../components/NavigationBar";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";
import ResumeAndSkillsElements from "../components/ResumeAndSkills";

const ResumeAndSkills = () => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return (
        <>
            <Helmet>
                <title>Resume | Tanaseth Julerttrakul</title>
            </Helmet>

            <Sidebar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <NavigationBar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <ResumeAndSkillsElements/>
        </>
    );
};

export default ResumeAndSkills;
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import ResumeAndSkills from "./pages/resumeandskills";
import Music from "./pages/music/index";
import Discography from "./pages/music/discography";
import Projects from "./pages/projects";
import ToEventually from "./pages/music/to/eventually";

function App() {
    if (window.location.host.split(".")[0] === "music") {
        return (
            <>
                <Router>
                    <Switch>
                        <Route path="/to/eventually">
                            <ToEventually />
                        </Route>
                        <Route path="/">
                            <Music />
                        </Route>
                    </Switch>
                </Router>
            </>
        );
    }

    return (
        <>
            <Router>
                <Switch>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/contact">
                        <Home />
                    </Route>
                    <Route path="/ktns/eventually">
                        <ToEventually />
                    </Route>
                    <Route path="/projects">
                        <Projects />
                    </Route>
                    <Route path="/resume">
                        <ResumeAndSkills />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;

import React from "react";
import {
    ResumeAndSkillsComponentsContainer,
    ResumeAndSkillsContainer,
    ResumeAndSkillsTitleContainer,
    ResumeAndSkillsTitleText,
    ResumeAndSkillsTitleTextContainer,
    SkillsSubtitleText,
    SkillsSubtitleContainer,
    ResumeAndSkillsMenuContainer,
    ResumeAndSkillsMenuResume,
    ResumeAndSkillsMenuLinkedIn,
    ResumeAndSkillsMenuContact,
} from "./ResumeAndSkillsElements";

const LINKEDIN_URL = "https://www.linkedin.com/in/tanasethj";
const RESUME_URL = "https://drive.google.com/file/d/1JDmz11cL1otRj4tsAG4lcUxCAmFN-Ehb/view?usp=sharing";

const ResumeAndSkillsElements = () => {
    return (
        <ResumeAndSkillsContainer>
            <ResumeAndSkillsComponentsContainer>
                <ResumeAndSkillsTitleContainer>
                    <ResumeAndSkillsTitleTextContainer>
                        <ResumeAndSkillsTitleText>I can do things.</ResumeAndSkillsTitleText>
                    </ResumeAndSkillsTitleTextContainer>

                    <SkillsSubtitleContainer>
                        <SkillsSubtitleText>From coding with Java, React, Go, or Python, to designing user interface, to music and video production.</SkillsSubtitleText>
                    </SkillsSubtitleContainer>

                    <ResumeAndSkillsMenuContainer>
                        <ResumeAndSkillsMenuContact to="/contact">Contact me ></ResumeAndSkillsMenuContact>
                        <ResumeAndSkillsMenuLinkedIn target="_blank" href={LINKEDIN_URL}>
                            More on LinkedIn >
                        </ResumeAndSkillsMenuLinkedIn>
                        <ResumeAndSkillsMenuResume target="_blank" href={RESUME_URL}>
                            Download resume
                        </ResumeAndSkillsMenuResume>
                    </ResumeAndSkillsMenuContainer>
                </ResumeAndSkillsTitleContainer>
            </ResumeAndSkillsComponentsContainer>
        </ResumeAndSkillsContainer>
    );
};

export default ResumeAndSkillsElements;

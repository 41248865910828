import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

// import background from "../../img/home_bg.JPG";

const background = "https://res.cloudinary.com/ktns/image/upload/v1631027515/backgrounds/home_bg_gk0ytv.jpg";

export const transitionIn = keyframes`
    from {
        -webkit-transform: translateY(20px);
        opacity: 0%;
    }

    to {
        -webkit-transform: translateY(0px);
        opacity: 100%;
    }
`;

export const HomeContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    font-family: "BasierSquare";

    background-color: #fff;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.23573179271708689) 10%, rgba(0, 0, 0, 0) 25%), url(${background});
    background-position: 0 55%;    
    background-repeat: no-repeat;


    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        background-position: 20% 55%;
    }

    @media screen and (max-width: 600px) {
        background-position: 25% 55%;
    }

    @media screen and (max-height: 450px) {
        background-position: 25% 55%;
        height: 200%;
    }
`;

export const HomeComponentsContainer = styled.div`
    position: absolute;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;

    color: #fff;
    mix-blend-mode: difference !important;
    filter: contrast(2);

    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-top: 10%;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }
`;

export const HelloContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
`;

export const TitleTextContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 5%;
    width: 90%;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const TitleText = styled.text``;

export const SubtitleTextContainer = styled.div`
    font-size: 36px;
    font-weight: 600;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
        margin-top: 10%;
    }
`;

export const SubtitleText = styled.text`
    color: #999;
`;

export const IntroductionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 875px) {
        position: absolute;
        bottom: 3rem;
        align-items: center;
        width: 100%;
    }
`;

export const IntroductionText = styled.text`
    color: #000;
`;

export const IntroductionTextContainer = styled.div`
    font-size: 56px;
    font-weight: 600;
    text-align: right;
    margin-right: 50px;
    animation-delay: 1s;
    animation: ${transitionIn} 1.5s ease-in-out;

    @media screen and (max-width: 875px) {
        text-align: center;
        font-size: 30px;
        margin-right: 0px;
    }
`;

export const SubtitleMenu = styled(Link)`
    text-decoration: none;
    color: #fff;

    &:hover {
        color: #777;
    }
`;

export const SubtitleMenuA = styled.a`
    text-decoration: none;
    color: #fff;

    &:hover {
        color: #777;
    }
`;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Navigation = styled.nav`
    margin-top: 0px;
    height: 140px;
    width: 100%;
    justify-content: center;
    top: 0px;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    z-index: 1;
    display: flex;

    will-change: opacity;
    mix-blend-mode: difference;
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 10;
    width: 100%;
    padding: 0 10px;
    max-width: 97%;
    font-family: "BasierSquare";
`;

export const NavigationLogo = styled(Link)`
    font-size: 1.8rem;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;

    @media screen and (max-width: 875px) {
        font-size: 1.4rem;
    }
`;

export const NavigationIcon = styled.div`
    display: none;

    @media screen and (max-width: 875px) {
        display: flex;
        position: absolute;
        top: 10%;
        right: 0px;
        transform: translate(-100%, 40px);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavigationMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 10px;

    @media screen and (max-width: 875px) {
        display: none;
    }
`;

export const NavigationItem = styled.li`
    height: 100px;
    flex-direction: column;
    font-size: 1.1rem;
`;

export const NavigationLink = styled(Link)`
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: #777;
        transition: 0.2s ease-in-out;
    }
`;

export const NavigationLinkA = styled.a`
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: #777;
        transition: 0.2s ease-in-out;
    }
`;

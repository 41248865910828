import React from "react";
import { MusicSidebarContainer, CloseIconContainer, CloseIcon, MusicSidebarMenuContainer, MusicSidebarMenu, MusicSidebarLink, MusicSidebarLinkA } from "./MusicSidebarElements";

const home_url = process.env.REACT_APP_URL_PROTOCOL + window.location.host.split('.').slice(1).join('.');

const MusicSidebar = (props) => {
    return (
        <MusicSidebarContainer isOpen={props.isOpen}>
            <CloseIconContainer onClick={props.toggleSidebar}>
                <CloseIcon />
            </CloseIconContainer>

            <MusicSidebarMenuContainer>
                <MusicSidebarMenu>
                    {/* <MusicSidebarLink to="/about" onClick={props.toggleSidebar}>
                        ABOUT
                    </MusicSidebarLink>
                    <MusicSidebarLink to="/discography" onClick={props.toggleSidebar}>
                        ♫ DISCOGRAPHY
                    </MusicSidebarLink>
                    <MusicSidebarLink to="/contact" onClick={props.toggleSidebar}>
                        CONTACT
                    </MusicSidebarLink> */}
                    <MusicSidebarLinkA href={home_url} onClick={props.toggleSidebar}>
                        TANASETH.CO
                    </MusicSidebarLinkA>
                </MusicSidebarMenu>
            </MusicSidebarMenuContainer>
        </MusicSidebarContainer>
    );
};

export default MusicSidebar;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

// import hero_bg from "../../img/about_hero.JPG";
// import we_bg from "../../img/we_bg.jpg";

const hero_bg = "https://res.cloudinary.com/ktns/image/upload/v1631027515/backgrounds/about_hero_kwvhzy.jpg";
const we_bg = "https://res.cloudinary.com/ktns/image/upload/v1631027514/backgrounds/we_bg_kl8srm.jpg";

export const transitionIn = keyframes`
    from {
        -webkit-transform: translateY(20px);
        opacity: 0%;
    }

    to {
        -webkit-transform: translateY(0px);
        opacity: 100%;
    }
`;

export const AboutContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    font-family: "BasierSquare";

    background-color: #fff;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.23573179271708689) 10%, rgba(0, 0, 0, 0) 25%), url(${hero_bg});
    background-position: 0% 20%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1800px) {
        background-position: 20% 20%;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        background-position: 40% 35%;
    }

    @media screen and (max-width: 600px) {
        background-position: 50% 40%;
    }

    @media screen and (max-height: 450px) {
        background-position: 40% 35%;
        height: 250%;
    }
`;

export const AboutComponentContainer = styled.div`
    margin-top: 5%;
    width: 50%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
        margin-top: 23%;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }

    color: #fff;
    mix-blend-mode: difference !important;
    filter: contrast(9);
`;

export const IntroductionTextContainer = styled.div`
    margin-bottom: 5%;
`;

export const IntroductionTitleContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    width: 90%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const IntroductionTitle = styled.text``;

export const IntroductionSubtitleContainer = styled.div`
    font-size: 36px;
    width: 100%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const IntroductionSubtitle = styled.text``;

export const SubtitleMenuContainer = styled.div`
    font-size: 36px;
    font-weight: 600;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.9s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
        margin-top: 10%;
    }
`;

export const SubtitleMenu = styled(Link)`
    text-decoration: none;
    color: #fff;

    &:hover {
        color: #777;
    }
`;

export const WorkExperienceContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    font-family: "BasierSquare";

    /* background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.23573179271708689) 10%, rgba(255, 255, 255, 0.01) 25%), url(${we_bg});
    background-size: 200%;
    background-position: 0% 100%; */

    @media screen and (max-height: 450px) {
        height: 50%;
    }
`;

export const WorkExperienceComponentsContainer = styled.div`
    margin-top: 5%;
    width: 50%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
        margin-top: 20%;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }

    mix-blend-mode: normal !important;
    filter: contrast(9);
`;

export const WorkExperienceTitleContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    width: 90%;
    margin-bottom: 5%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        margin-bottom: 6%;
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        margin-bottom: 6%;
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 10%;
        width: 100%;
        font-size: 36px;
    }
`;

export const WorkExperienceTitle = styled.text``;

export const WorkExperienceObjectsContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;
`;

export const WorkExperienceObject = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

    @media screen and (max-width: 1800px) {
        margin-bottom: 6%;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        margin-bottom: 6%;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 10%;
    }
`;

export const WorkExperienceDate = styled.text`
    color: #767676;
    font-weight: 600;
    font-size: 30px;

    @media screen and (max-width: 1800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 22px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const WorkExperienceObjectTitleComponentsContainer = styled.div``;

export const WorkExperienceObjectTitleCompanyName = styled.a`
    color: #000;
    font-size: 36px;
    font-weight: 600;
    text-decoration: none;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 24px;
    }

    &:hover {
        color: ${(props) => (props.companyColour ? props.companyColour : "#777")};
    }
`;

export const WorkExperienceObjectTitlePosition = styled.text`
    font-size: 36px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 24px;
    }
`;

export const WorkExperienceObjectSubtitle = styled.text`
    font-size: 30px;
    width: 100%;
    color: #777;

    @media screen and (max-width: 1800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 22px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const EducationContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    font-family: "BasierSquare";

    @media screen and (max-width: 1800px) {
        background-position: 0% 120%;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        background-position: 0% 100%;
    }

    @media screen and (max-width: 600px) {
        background-position: 0% 100%;
    }

    @media screen and (max-height: 450px) {
        background-position: 0% 100%;
        height: 50%;
    }
`;

export const EducationComponentsContainer = styled.div`
    margin-top: 5%;
    width: 50%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
        margin-top: 20%;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }

    mix-blend-mode: normal !important;
    filter: contrast(9);
`;

export const EducationTitleContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    width: 90%;
    margin-bottom: 5%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        margin-bottom: 6%;
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        margin-bottom: 6%;
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 10%;
        width: 100%;
        font-size: 36px;
    }
`;

export const EducationTitle = styled.text``;

export const EducationObjectsContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;
`;

export const EducationObject = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

    @media screen and (max-width: 1800px) {
        margin-bottom: 6%;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        margin-bottom: 6%;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 10%;
    }
`;

export const EducationDate = styled.text`
    color: #767676;
    font-weight: 600;
    font-size: 30px;

    @media screen and (max-width: 1800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 22px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const EducationObjectTitleComponentsContainer = styled.div``;

export const EducationObjectTitleSchoolName = styled.a`
    font-size: 36px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 24px;
    }

    &:hover {
        color: ${(props) => (props.companyColour ? props.companyColour : "#777")};
        filter: invert(1);
    }
`;

export const EducationObjectTitle = styled.text`
    font-size: 36px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 32px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 24px;
    }
`;

export const EducationObjectSubtitle = styled.text`
    font-size: 30px;
    width: 100%;
    color: #777;

    @media screen and (max-width: 1800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 22px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const EduAndWEBackground = styled.div`
    width: 100%;
    height: 200%;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;

    background-color: #fff;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.23573179271708689) 10%, rgba(255, 255, 255, 0.01) 25%), url(${we_bg});
    background-size: auto 100%;
    background-repeat: no-repeat;

    @media screen and (max-height: 450px) {
        background-size: auto 100%;
        top: 250%;
        height: 400%;
    }
`;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const projects_hero_bg = "https://res.cloudinary.com/ktns/image/upload/v1631125408/backgrounds/projects_hero_bg_zgbhpe.jpg";
const projects_footer_bg = "https://res.cloudinary.com/ktns/image/upload/v1631123006/backgrounds/projects_footer_bg_vyywna.jpg";

export const transitionIn = keyframes`
    from {
        -webkit-transform: translateY(20px);
        opacity: 0%;
    }

    to {
        -webkit-transform: translateY(0px);
        opacity: 100%;
    }
`;

export const scrollLeft = keyframes`
    from {
        transform: translateX(0p);
    }

    to {
        transform: translateX(-100%);
    }
`;

export const ProjectsContainer = styled.div`
    width: 100%;
    font-family: "BasierSquare";
`;

export const ProjectsTitleComponentsContainerBackground = styled.div`
    display: flex;
    min-height: 100vh;
    min-width: 100%;
    justify-content: center;
    align-items: center;

    background: rgb(18, 18, 18);
    background-image: linear-gradient(0deg, rgba(34, 34, 34, 1) 0%, rgba(18, 18, 18, 0.23573179271708689) 25%, rgba(0, 0, 0, 0) 50%), url(${projects_hero_bg});
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-height: 450px) {
        height: 150vh;
    }
`;

export const ProjectsTitleComponentsContainer = styled.div`
    width: 50%;
    margin-top: 5%;

    color: #fff;
    mix-blend-mode: difference;
    -webkit-filter: contrast(9);

    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }
`;

export const ProjectsTitleContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
`;

export const ProjectsTitleTextContainer = styled.div`
    font-size: 100px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const ProjectsTitleText = styled.text``;

export const ProjectsSubtitleContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;

    @media screen and (max-width: 600px) {
        margin-bottom: 12%;
    }
`;

export const ProjectsSubtitleText = styled.text`
    font-size: 32px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
`;

export const ProjectsObjectsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20% 0px 20% 0px;

    background: rgb(18, 18, 18);
    background: linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 1) 50%, rgba(34, 34, 34, 1) 75%, rgba(34, 34, 34, 1) 100%);
`;

export const ProjectsObjectsComponentsContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;

    color: #fff;

    @media screen and (max-height: 800px) {
        width: 75%;
    }
`;

export const ProjectsObjectTitleContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
`;

export const ProjectsObjectTitleTextContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    text-decoration: none;
    width: 90%;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const ProjectsObjectTitleText = styled.text``;

export const ProjectsObjectSubtitleContainer = styled.div`
    width: 100%;
    margin-top: 50px;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;
`;

export const ProjectsObjectSubtitleText = styled.text`
    font-size: 32px;

    @media screen and (max-width: 1800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
`;

export const ProjectsObjectDate = styled.text`
    color: #767676;
    font-weight: 600;
    font-size: 30px;

    @media screen and (max-width: 1800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 22px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
`;

export const ProjectsObjectFrameworksContainer = styled.div`
    color: #767676;
    font-weight: 600;
    font-size: 34px;
    margin-top: 5px;

    @media screen and (max-width: 1800px) {
        font-size: 30px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 600px) {
        font-size: 22px;
    }
`;

export const ProjectsObjectFrameworks = styled.text``;

export const ProjectsObjectPhotosContainer = styled.div`
    color: #767676;
    font-weight: 600;
    font-size: 34px;
    margin-top: 65px;
    width: 100%;
    height: auto;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 1800px) {
        font-size: 30px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 600px) {
        font-size: 22px;
    }
`;

export const ProjectObjectPhoto = styled.img`
    max-height: 600px;
    width: auto;
    margin-right: 15px;

    @media screen and (max-width: 875px) {
        max-height: 400px;
    }
`;

export const ProjectsFooterComponentsContainerBackground = styled.div`
    display: flex;
    min-height: 100vh;
    min-width: 100%;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(180deg, rgba(34, 34, 34, 1) 0%, rgba(34, 34, 34, 0.23573179271708689) 10%, rgba(0, 0, 0, 0) 25%), url(${projects_footer_bg});
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-height: 450px) {
        height: 150vh;
    }
`;

export const ProjectsFooterComponentsContainer = styled.div`
    width: 50%;

    color: #fff;
    mix-blend-mode: difference;

    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }
`;

export const ProjectsFooterTitleContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
`;

export const ProjectsFooterTitleTextContainer = styled.div`
    font-size: 100px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const ProjectsFooterTitleText = styled.text``;

export const ProjectsFooterSubtitleContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;

    @media screen and (max-width: 600px) {
        margin-bottom: 12%;
    }
`;

export const ProjectsFooterSubtitleText = styled.text`
    font-size: 32px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
`;

export const ProjectsFooterMenuContact = styled(Link)`
    text-decoration: none;
    color: #fff;
    padding: 7px 18px 7px 18px;
    border-radius: 1000px;
    border: 4px solid #fff;
    text-align: center;

    &:hover {
        color: #000;
        background-color: #fff;
    }
`;

export const ProjectsFooterMenuContainer = styled.div`
    font-size: 36px;
    font-weight: 600;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.9s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 30px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 600px) {
        font-size: 22px;
        margin-top: 10%;
    }
`;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

// import sr_bg from "../../img/sr_bg.JPG";

const sr_bg = "https://res.cloudinary.com/ktns/image/upload/v1631027515/backgrounds/sr_bg_ahezri.jpg";

export const transitionIn = keyframes`
    from {
        -webkit-transform: translateY(20px);
        opacity: 0%;
    }

    to {
        -webkit-transform: translateY(0px);
        opacity: 100%;
    }
`;

export const scrollLeft = keyframes`
    from {
        transform: translateX(0p);
    }

    to {
        transform: translateX(-100%);
    }
`;

export const ResumeAndSkillsContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    font-family: "BasierSquare";

    background-color: #fff;
    background-image: url(${sr_bg});
    background-position: 0% 0%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1800px) {
        background-position: 20% 0%;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        background-position: 40% 32%;
    }

    @media screen and (max-width: 600px) {
        background-position: 50% 36%;
    }

    @media screen and (max-height: 450px) {
        background-position: 40% 30%;
        height: 250%;
    }
`;

export const ResumeAndSkillsComponentsContainer = styled.div`
    position: absolute;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;

    color: #fff;
    mix-blend-mode: difference !important;
    filter: contrast(2);

    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }

    @media screen and (max-height: 800px) {
        width: 75%;
    }
`;

export const ResumeAndSkillsTitleContainer = styled.div`
    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
`;

export const ResumeAndSkillsTitleTextContainer = styled.div`
    font-size: 100px;
    font-weight: 600;
    text-decoration: none;
    width: 90%;

    @media screen and (max-width: 1800px) {
        font-size: 72px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 60px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 36px;
    }
`;

export const ResumeAndSkillsTitleText = styled.text``;

export const SkillsSubtitleContainer = styled.div`
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10%;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: both;

    @media screen and (max-width: 600px) {
        margin-bottom: 12%;
    }
`;

export const SkillsSubtitleText = styled.text`
    font-size: 32px;
    font-weight: 600;

    @media screen and (max-width: 1800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
`;

export const ResumeAndSkillsMenuContainer = styled.div`
    font-size: 36px;
    font-weight: 600;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    animation: ${transitionIn} 0.25s ease-in-out;
    animation-delay: 0.9s;
    animation-fill-mode: both;

    @media screen and (max-width: 1800px) {
        font-size: 30px;
    }

    @media screen and (max-width: 900px), screen and (max-height: 800px) {
        font-size: 26px;
    }

    @media screen and (max-width: 600px) {
        font-size: 22px;
        margin-top: 10%;
    }
`;

export const ResumeAndSkillsMenuResume = styled.a`
    text-decoration: none;
    color: #fff;
    padding: 7px 18px 7px 18px;
    border-radius: 1000px;
    border: 4px solid #fff;
    text-align: center;

    &:hover {
        color: #000;
        background-color: #fff;
    }
`;

export const ResumeAndSkillsMenuLinkedIn = styled.a`
    text-decoration: none;
    color: #fff;
    margin-bottom: 15px;
    text-align: center;

    &:hover {
        color: #777;
    }
`;

export const ResumeAndSkillsMenuContact = styled(Link)`
    text-decoration: none;
    color: #fff;
    margin-bottom: 15px;
    text-align: center;

    &:hover {
        color: #777;
    }
`;

import React from "react";
import { SidebarContainer, CloseIconContainer, CloseIcon, SidebarMenuContainer, SidebarMenu, SidebarLink, SidebarLinkA } from "./SidebarElements";

const Sidebar = (props) => {
    return (
        <SidebarContainer isOpen={props.isOpen}>
            <CloseIconContainer onClick={props.toggleSidebar}>
                <CloseIcon />
            </CloseIconContainer>

            <SidebarMenuContainer>
                <SidebarMenu>
                    <SidebarLink to="/about" onClick={props.toggleSidebar}>
                        ABOUT
                    </SidebarLink>
                    <SidebarLink to="/projects" onClick={props.toggleSidebar}>
                        PROJECTS
                    </SidebarLink>
                    <SidebarLinkA href={process.env.REACT_APP_MUSIC_URL_SUBDOMAIN + window.location.host} onClick={props.toggleSidebar}>
                        ♫ MUSIC
                    </SidebarLinkA>
                    <SidebarLink to="/contact" onClick={props.toggleSidebar}>
                        CONTACT
                    </SidebarLink>
                    <SidebarLink to="/resume" onClick={props.toggleSidebar}>
                        RESUME
                    </SidebarLink>
                </SidebarMenu>
            </SidebarMenuContainer>
        </SidebarContainer>
    );
};

export default Sidebar;

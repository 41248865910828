import React from "react";
import {
    ArtistLogoContainer,
    ArtistTitleContainer,
    ArtistTitleText,
    ArtistTitleTextContainer,
    MusicComponentsContainer,
    MusicContainer,
    MusicHeroTitleContainer,
    MusicMenu,
    MusicMenuContainer,
    MusicSocialMediaBarContainer,
    MusicSocialMediaIconsContainer,
    MusicSubtitleText,
    MusicSubtitleTextContainer,
    MusicTitleContainer,
    MusicTitleText,
    MusicTitleTextContainer,
    MusicVideoBackground,
    MusicVideoBackgroundContainer,
} from "./MusicElements";
import { ReactComponent as MusicHeroTitle } from "../../img/music_hero_title.svg";
import { ReactComponent as ArtistTitle } from "../../img/artist_logo.svg";
import { ReactComponent as SpotifyLogo } from "../../img/spotify_logo.svg";
import { ReactComponent as YoutubeLogo } from "../../img/youtube_logo.svg";
import { ReactComponent as AppleMusicLogo } from "../../img/applemusic_logo.svg";

const listenNowUrl = "https://push.fm/fl/eventually";
const backgroundVideo = "https://res.cloudinary.com/ktns/video/upload/q_auto:eco/v1631029664/videos/music_bg_hero_oouzoc.mp4";

function MusicElements(props) {
    return (
        <>
            <MusicVideoBackgroundContainer>
                <MusicVideoBackground autoPlay loop muted playsInline webkit-playsInline>
                    <source src={backgroundVideo} type="video/mp4" />
                </MusicVideoBackground>
            </MusicVideoBackgroundContainer>

            <MusicContainer>
                <MusicComponentsContainer>
                    <MusicTitleContainer>
                        {/* <ArtistTitleContainer>
                            <ArtistTitle/>
                        </ArtistTitleContainer> */}
                        <MusicHeroTitleContainer>
                            <MusicHeroTitle />
                        </MusicHeroTitleContainer>
                    </MusicTitleContainer>
                    <MusicMenuContainer>
                        <MusicMenu target="_blank" href={listenNowUrl}>
                            Listen now >
                        </MusicMenu>
                    </MusicMenuContainer>

                    {/* <MusicSocialMediaBarContainer>
                        <MusicSocialMediaIconsContainer>
                            <SpotifyLogo height="40px" width="auto" color="#fff" />
                        </MusicSocialMediaIconsContainer>
                        <MusicSocialMediaIconsContainer>
                            <YoutubeLogo height="35px" width="auto" color="#fff" />
                        </MusicSocialMediaIconsContainer>
                        <MusicSocialMediaIconsContainer>
                            <AppleMusicLogo height="30px" width="auto" color="#fff" style={{ marginTop: "-6px" }} />
                        </MusicSocialMediaIconsContainer>
                    </MusicSocialMediaBarContainer> */}
                </MusicComponentsContainer>
            </MusicContainer>
        </>
    );
}

export default MusicElements;

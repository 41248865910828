import React, { useState } from "react";
import {
    ContactContainer,
    SocialMediaTextContainer,
    SocialMediaLink,
    TitleTextContainer,
    TitleText,
    EmailTextContainer,
    EmailText,
    ContactAndEmailContainer,
    ContactWrapper,
    SocialMediaWrapper,
    CopyIcon,
    CopyIconContainer,
    EmailTextAncCopyIconContainer,
    CopiedToClipboardTextContainer,
    CopiedIcon,
    CopiedToClipboardText,
} from "./ContactElements.js";

const email = "hello@tanaseth.co";

const ContactElements = () => {
    const [copySuccess, setCopySuccess] = useState(false);

    const copyToClipboard = (str) => {
        const el = document.createElement("textarea");
        el.value = email;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopySuccess(true);
        setTimeout(function () {
            setCopySuccess(false);
        }, 2000);
    };

    return (
        <ContactContainer>
            <ContactWrapper>
                <ContactAndEmailContainer>
                    <TitleTextContainer>
                        <TitleText>Contact me</TitleText>
                    </TitleTextContainer>
                    <EmailTextAncCopyIconContainer>
                        <CopyIconContainer>
                            {copySuccess && <CopiedIcon />}
                            {!copySuccess && <CopyIcon onClick={() => copyToClipboard()} />}
                        </CopyIconContainer>
                        <EmailTextContainer>
                            <EmailText
                                onClick={() => {
                                    copyToClipboard();
                                }}
                            >
                                {email}
                            </EmailText>
                        </EmailTextContainer>
                    </EmailTextAncCopyIconContainer>
                    {copySuccess && (
                        <CopiedToClipboardTextContainer>
                            <CopiedToClipboardText>&lt;Copied to Clipboard/&gt;</CopiedToClipboardText>
                        </CopiedToClipboardTextContainer>
                    )}
                </ContactAndEmailContainer>
            </ContactWrapper>

            <SocialMediaWrapper>
                <SocialMediaTextContainer>
                    <SocialMediaLink href="https://instagram.com/ktns_" target="_blank">
                        Instagram
                    </SocialMediaLink>
                </SocialMediaTextContainer>
                <SocialMediaTextContainer>
                    <SocialMediaLink href="https://linkedin.com/in/tanasethj" target="_blank">
                        LinkedIn
                    </SocialMediaLink>
                </SocialMediaTextContainer>
            </SocialMediaWrapper>
        </ContactContainer>
    );
};

export default ContactElements;

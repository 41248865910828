import React, { useState } from "react";
import MusicNavigationBar from "../../components/Music/MusicNavigationBar";
import MusicSidebar from "../../components/Music/MusicSidebar";
import { Helmet } from "react-helmet";
import MusicElements from "../../components/Music";

const Music = () => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return (
        <>
            <Helmet>
                <title>ktns | Official Website</title>
            </Helmet>

            <MusicSidebar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <MusicNavigationBar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <MusicElements/>
        </>
    );
};

export default Music;
import React from "react";
import {
    ProjectsTitleComponentsContainer,
    ProjectsContainer,
    ProjectsSubtitleContainer,
    ProjectsSubtitleText,
    ProjectsTitleContainer,
    ProjectsTitleText,
    ProjectsTitleTextContainer,
    ProjectsTitleComponentsContainerBackground,
    ProjectsObjectsContainer,
    ProjectsObjectsComponentsContainer,
    ProjectsObjectTitleContainer,
    ProjectsObjectTitleText,
    ProjectsObjectTitleTextContainer,
    ProjectsObjectSubtitleContainer,
    ProjectsObjectSubtitleText,
    ProjectsObjectDate,
    ProjectsObjectFrameworks,
    ProjectsObjectFrameworksContainer,
    ProjectsObjectPhotosContainer,
    ProjectObjectPhoto,
    ProjectsFooterComponentsContainerBackground,
    ProjectsFooterComponentsContainer,
    ProjectsFooterTitleContainer,
    ProjectsFooterTitleTextContainer,
    ProjectsFooterTitleText,
    ProjectsFooterMenuContainer,
    ProjectsFooterMenuContact,
} from "./ProjectsElements";

import { projectsData } from "../../data/projects";

function ProjectsElements() {
    return (
        <>
            <ProjectsContainer>
                <ProjectsTitleComponentsContainerBackground>
                    <ProjectsTitleComponentsContainer>
                        <ProjectsTitleContainer>
                            <ProjectsTitleTextContainer>
                                <ProjectsTitleText>Projects</ProjectsTitleText>
                            </ProjectsTitleTextContainer>
                            <ProjectsSubtitleContainer>
                                <ProjectsSubtitleText>A collection. A portfolio. Nothing to say more. Please enjoy.</ProjectsSubtitleText>
                            </ProjectsSubtitleContainer>
                        </ProjectsTitleContainer>
                    </ProjectsTitleComponentsContainer>
                </ProjectsTitleComponentsContainerBackground>

                {Object.keys(projectsData).map((iteration) => (
                    <ProjectsObjectsContainer>
                        <ProjectsObjectsComponentsContainer>
                            <ProjectsObjectTitleContainer>
                                <ProjectsObjectDate>
                                    {projectsData[iteration].catagory} · {projectsData[iteration].year}
                                </ProjectsObjectDate>
                                <ProjectsObjectTitleTextContainer>
                                    <ProjectsObjectTitleText>{projectsData[iteration].title}</ProjectsObjectTitleText>
                                </ProjectsObjectTitleTextContainer>
                                <ProjectsObjectFrameworksContainer>
                                    <ProjectsObjectFrameworks>{projectsData[iteration].frameworks}</ProjectsObjectFrameworks>
                                </ProjectsObjectFrameworksContainer>
                                <ProjectsObjectSubtitleContainer>
                                    <ProjectsObjectSubtitleText>{projectsData[iteration].background}</ProjectsObjectSubtitleText>
                                </ProjectsObjectSubtitleContainer>
                                <ProjectsObjectSubtitleContainer>
                                    <ProjectsObjectSubtitleText>{projectsData[iteration].features}</ProjectsObjectSubtitleText>
                                </ProjectsObjectSubtitleContainer>
                                {projectsData[iteration].photos.length !== 0 && (
                                    <ProjectsObjectPhotosContainer>
                                        {Object.keys(projectsData[iteration].photos).map((photo_iteration) => (
                                            <ProjectObjectPhoto src={projectsData[iteration].photos[photo_iteration].src} />
                                        ))}
                                    </ProjectsObjectPhotosContainer>
                                )}
                            </ProjectsObjectTitleContainer>
                        </ProjectsObjectsComponentsContainer>
                    </ProjectsObjectsContainer>
                ))}

                <ProjectsFooterComponentsContainerBackground>
                    <ProjectsFooterComponentsContainer>
                        <ProjectsFooterTitleContainer>
                            <ProjectsFooterTitleTextContainer>
                                <ProjectsFooterTitleText>Like what you just saw? Let's get in touch!</ProjectsFooterTitleText>
                            </ProjectsFooterTitleTextContainer>

                            <ProjectsFooterMenuContainer>
                                <ProjectsFooterMenuContact to="/contact">Contact me</ProjectsFooterMenuContact>
                            </ProjectsFooterMenuContainer>
                        </ProjectsFooterTitleContainer>
                    </ProjectsFooterComponentsContainer>
                </ProjectsFooterComponentsContainerBackground>
            </ProjectsContainer>
        </>
    );
}

export default ProjectsElements;

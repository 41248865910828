import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const MusicSidebarContainer = styled.div`
    z-index: 1000;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    display: grid;
    top: 0;
    left: 0;
    -webkit-transition: 0.2s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    left: ${({ isOpen }) => (isOpen ? '0' : '200%')};
    font-family: 'BasierSquare';
`

export const CloseIcon = styled(FaTimes)`
    color: #000;

    &:hover {
        color: #999;
        -webkit-transition: 0.2s ease-in-out;
    }
`

export const CloseIconContainer = styled.div`
    position: absolute;
    background: transparent;
    cursor: pointer;
    font-size: 2rem;
    outline: none;
    top: 1.5rem;
    right: 1.5rem;
`

export const MusicSidebarMenuContainer = styled.div`
    color: #fff;
`

export const MusicSidebarMenu = styled.ul`
    display: grid;
    width: 100%;
    text-align: center;
    padding: 0;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 100px);

    @media screen and (max-height: 480px) {
        grid-template-rows: repeat(4, 60px);
    };
`

export const MusicSidebarLink = styled(Link)`
    font-size: 28px;
    color: #000;
    font-weight: bold;
    text-decoration: none;
    list-style: none;
    border: none;
    outline: none;
    -webkit-transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #999;
        -webkit-transition: 0.2s ease-in-out;
    }
`

export const MusicSidebarLinkA = styled.a`
    font-size: 28px;
    color: #000;
    font-weight: bold;
    text-decoration: none;
    list-style: none;
    border: none;
    outline: none;
    -webkit-transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #999;
        -webkit-transition: 0.2s ease-in-out;
    }
`


import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const fan_link = "https://push.fm/fl/eventually";

const ToEventually = () => {
    useEffect(() => {
        window.location.href = fan_link;
    });

    return (
        <>
            <Helmet>
                <title>Eventually | ktns</title>
            </Helmet>
        </>
    );
};

export default ToEventually;

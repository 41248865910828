import styled from "styled-components";
import { Link } from "react-router-dom";

export const MusicNavigation = styled.nav`
    margin-top: 0px;
    height: 140px;
    width: 100%;
    justify-content: center;
    align-items: center;
    top: 0px;
    font-size: 1rem;
    position: fixed;
    z-index: 1;
    display: flex;

    mix-blend-mode: difference;
    -webkit-filter: invert(1) grayscale(1);

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const MusicNavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    width: 100%;
    padding: 0 10px;
    max-width: 97%;
    font-family: "BasierSquare";
`;

export const MusicNavigationLogoContainer = styled.li`
    height: 100px;
    display: flex;
    align-items: center;
`;

export const MusicNavigationLogo = styled(Link)`
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 9%;

    @media screen and (max-height: 800px) {
        margin-bottom: 8.75%;
    }

    @media screen and (max-height: 700px) {
        margin-bottom: 8.6%;
    }

    @media screen and (max-width: 875px) {
        margin-top: 3.5px;
        margin-left: 10px;
    }

    &:hover {
        color: #777;
        transition: 0.2s ease-in-out;
    }
`;

export const MusicNavigationIcon = styled.div`
    display: none;

    @media screen and (max-width: 875px) {
        display: flex;
        position: absolute;
        top: 10%;
        right: 0px;
        transform: translate(-100%, 40px);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const MusicNavigationMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 10px;

    @media screen and (max-width: 875px) {
        display: none;
    }
`;

export const MusicNavigationItem = styled.li`
    height: 100px;
    flex-direction: column;
    font-size: 1.1rem;
`;

export const MusicNavigationLink = styled(Link)`
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: #777;
        transition: 0.2s ease-in-out;
    }
`;

export const MusicNavigationLinkA = styled.a`
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: #777;
        transition: 0.2s ease-in-out;
    }
`;

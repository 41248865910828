import React, { useState } from "react";
import NavigationBar from "../components/NavigationBar";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";
import ProjectsElements from "../components/Projects";

const Projects = () => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    function toggleSidebar() {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return (
        <>
            <Helmet>
                <title>Projects | Tanaseth Julerttrakul</title>
            </Helmet>

            <Sidebar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <NavigationBar isOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
            <ProjectsElements/>
        </>
    );
};

export default Projects;